<template>
    <div class="userGuideCRUD">
        <a-row :gutter="24" type="flex">
            <a-col :span="24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template>
                        <v-data-table
                            :headers="tableColumns"
                            :items="isLoadingTutorialDetail ? [] : tutorialDetailList"
                            :loading="isLoadingTutorialDetail"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                            class="elevation-1"
                            :loading-text="$t('cms.loading')"
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-dialog v-model="dialogCU" persistent max-width="1000px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                dark
                                                class="mb-2"
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="onAddNewItem()"
                                                small
                                            >
                                                <v-icon small> mdi-plus </v-icon>
                                                {{ $t('cms.add_new') }}
                                            </v-btn>
                                        </template>
                                        <ModalTutorial
                                            :cuItem="cuItem"
                                            :closeCU="closeCU"
                                            :save="onSaveItem"
                                            :dialogEdit="dialogEdit"
                                            v-if="dialogCU"
                                        >
                                        </ModalTutorial>
                                    </v-dialog>
                                    <v-spacer></v-spacer>
                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <ModalDelete :closeDelete="closeDelete" :deleteItemConfirm="deleteItemConfirm">
                                        </ModalDelete>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.title`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ item.title }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ convertTimeZone(+item.created_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.updated_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ convertTimeZone(+item.updated_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn color="info" class="mr-2" small @click="onUpdateItem(item)">
                                    <v-icon small> mdi-pencil </v-icon>
                                    &nbsp;{{ $t('form.edit') }}
                                </v-btn>
                                <v-btn color="error" dark class="mr-2" small @click="onDeleteItem(item)">
                                    <v-icon small> mdi-delete </v-icon>
                                    &nbsp;{{ $t('form.delete') }}
                                </v-btn>
                            </template>
                        </v-data-table>
                    </template>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ModalTutorial from '../../components/CMS/Modal/Tutorial.vue';
import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import TutorialDetail from './TutorialDetail.vue';

import { CRUD } from '../../constants';
import { mixinToasts, mixinFormats, mixinLoadings } from '../../mixins';
import * as _ from 'lodash';

export default {
    mixins: [mixinToasts, mixinFormats, mixinLoadings],
    props: {
        tutorial: { type: Object },
    },
    components: { ModalTutorial, ModalDelete, TutorialDetail },
    data() {
        return {
            tableColumns: [
                { text: this.$t('form.title'), value: 'title', sortable: false, width: '300px' },
                {
                    text: this.$t('form.created_at'),
                    value: 'created_at_unix_timestamp',
                    sortable: false,
                    width: '175px',
                },
                {
                    text: this.$t('form.updated_at'),
                    value: 'updated_at_unix_timestamp',
                    sortable: false,
                    width: '175px',
                },
                { text: this.$t('cms.action'), value: 'actions', sortable: false, width: '210px' },
            ],
            page: 1,
            cuIndex: -1,
            cuItem: {},
            dialogCU: false,
            dialogDelete: false,
            dialogNoti: false,
            dialogEdit: false,
        };
    },
    computed: {
        ...mapState('tutorialDetail', ['isLoadingTutorialDetail', 'tutorialDetailList', 'tutorialDetailPagination']),
    },
    async mounted() {
        await this.getList();
    },
    watch: {
        page(val) {
            this.getList();
        },
        dialogCU(val) {
            val || this.closeCU();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogNoti(val) {
            val || this.closeNoti();
        },
        tutorial() {
            this.getList();
        },
    },
    methods: {
        ...mapActions('tutorialDetail', [
            'getTutorialDetailList',
            'postTutorialDetail',
            'putTutorialDetail',
            'deleteTutorialDetail',
        ]),

        async getList() {
            await this.getTutorialDetailList({ tutorial_id: this.tutorial.id });
        },

        onAddNewItem() {
            this.dialogCU = true;
            this.dialogEdit = false;
        },

        onUpdateItem(item) {
            this.dialogCU = true;
            this.dialogEdit = true;
            this.cuIndex = this.tutorialDetailList.indexOf(item);
            this.cuItem = Object.assign({}, item);
        },

        onDeleteItem(item) {
            this.dialogDelete = true;
            this.cuIndex = this.tutorialDetailList.indexOf(item);
            this.cuItem = Object.assign({}, item);
        },

        closeCU() {
            this.dialogCU = false;
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.cuItem = {};
                this.cuIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.cuItem = {};
                this.cuIndex = -1;
            });
        },

        closeNoti() {
            this.dialogNoti = false;
        },

        async deleteItemConfirm() {
            this.closeDelete();
            this.showLoading();
            const res = await this.deleteTutorialDetail(this.cuItem.id);

            if (res?.isSuccess) {
                await this.getList();
                this.showSuccess(CRUD.DELETE);
            } else {
                this.showError(res?.message);
            }
            this.hideLoading();
        },

        async onSaveItem(data, callback) {
            let response = {};

            this.showLoading();

            if (this.cuIndex === -1) {
                let body = { ...data, tutorial_id: this.tutorial.id };

                if (this.tutorialDetailList.length === 0) {
                    body.step = 1;
                } else {
                    const itemMaxStep = _.maxBy(this.tutorialDetailList, (item) => item.step);
                    body.step = itemMaxStep.step + 1;
                }

                response = await this.postTutorialDetail(body);
            } else {
                response = await this.putTutorialDetail(data);
            }

            callback(response?.isSuccess);
            if (response?.isSuccess) {
                await this.getList();
                this.showSuccess(this.cuIndex === -1 ? CRUD.CREATE : CRUD.UPDATE);
            } else {
                this.showError(response?.message);
            }

            this.hideLoading();
        },
    },
};
</script>

<style lang="scss"></style>
