var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userGuideCRUD"},[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ padding: 0 }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('h5',{staticClass:"font-semibold m-0"},[_vm._v(_vm._s(_vm.$t('table_title.Tutorial')))])])],1)]},proxy:true}])},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableColumns,"items":_vm.tutorialList,"loading":_vm.isLoadingTutorial,"page":_vm.page,"no-data-text":_vm.$t('cms.no_data_available'),"single-expand":true,"loading-text":_vm.$t('cms.loading'),"hide-default-footer":"","show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.onAddNewItem()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('cms.add_new'))+" ")],1)]}}]),model:{value:(_vm.dialogCU),callback:function ($$v) {_vm.dialogCU=$$v},expression:"dialogCU"}},[(_vm.dialogCU)?_c('ModalTutorial',{attrs:{"cuItem":_vm.cuItem,"closeCU":_vm.closeCU,"save":_vm.onSaveItem,"dialogEdit":_vm.dialogEdit}}):_vm._e()],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('ModalDelete',{attrs:{"closeDelete":_vm.closeDelete,"deleteItemConfirm":_vm.deleteItemConfirm}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogNoti),callback:function ($$v) {_vm.dialogNoti=$$v},expression:"dialogNoti"}},[_c('ModalNoti',{attrs:{"close":_vm.closeNoti,"message":_vm.formMessage}})],1),_c('div',{staticClass:"search"},[_c('a-input-search',{attrs:{"placeholder":_vm.$t('header.search')},on:{"change":_vm.onSearchItem},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-2"},[_c('span',[_vm._v(_vm._s(item.title))])])]}},{key:"item.created_at_unix_timestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.convertTimeZone(+item.created_at_unix_timestamp)))])])]}},{key:"item.updated_at_unix_timestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.convertTimeZone(+item.updated_at_unix_timestamp)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.onUpdateItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v("  "+_vm._s(_vm.$t('form.edit'))+" ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","dark":"","small":""},on:{"click":function($event){return _vm.onDeleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v("  "+_vm._s(_vm.$t('form.delete'))+" ")],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"tutorial-detail-table",attrs:{"colspan":headers.length}},[_c('TutorialDetail',{attrs:{"tutorial":item}})],1)]}}],null,true)}),_c('div',{staticClass:"text-center py-5"},[_c('f-pagination',{attrs:{"pageSize":_vm.tutorialPagination.pageSize,"totalItems":_vm.tutorialPagination.totalItems,"disabled":_vm.isLoadingTutorial},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }